import { ExtendedSources, GSuiteSources, O365Sources } from '@outmind/types';
import React from 'react';

import { SourceLogo } from '../../../SourceLogo';
import { useStyles } from './styles';

/**
 * Renders the source icon
 */
export const SourceIcon: React.FC<SourceIconProps> = ({ source }) => {
  const classes = useStyles();

  if (source === 'o365') {
    return (
      <div className={classes.multiSourceLogoContainer}>
        {O365Sources.map((_source, i) => (
          <div className={classes.sourceLogoContainer} style={{ zIndex: 5 - i }}>
            <SourceLogo className={classes.sourceLogoImage} source={_source} />
          </div>
        ))}
      </div>
    );
  }
  if (source === 'gsuite') {
    return (
      <div className={classes.multiSourceLogoContainer}>
        {GSuiteSources.map((_source, i) => (
          <div className={classes.sourceLogoContainer} style={{ zIndex: 5 - i }}>
            <SourceLogo className={classes.sourceLogoImage} source={_source} />
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className={classes.sourceLogoContainer}>
      <SourceLogo className={classes.sourceLogoImage} source={source} />
    </div>
  );
};

interface SourceIconProps {
  source: ExtendedSources;
}
