import { Connector, ConnectorCredentialsStatus, ConnectorSyncStatus } from '@outmind/types';
import React, { useState } from 'react';

import {
  useConnectorsSyncStates,
  useFeatureFlags,
  useStartSync,
  useTranslations,
  useUpdateConnector,
  useUserProfile,
} from '../../hooks';
import { ListItem, ListItemText } from '../../material';
import { Actions, useDispatch } from '../../store';
import { ConnectorStatusWarning } from '../ConnectorStatusWarning';
import { ConfirmActionDialog } from '../CustomDialog/ConfirmActionDialog';
import { DeleteConnector } from '../DeleteConnector';
import { SourceLogo } from '../SourceLogo';
import { ManageSourceMenu } from './ManageSourceMenu';
import { SharedConnectorChip } from './SharedConnectorChip';
import { useStyles } from './styles';

export const ManageSourceElement: React.FC<ManageSourceElementProps> = ({ connector }) => {
  const { id: connectorId, email, name, notDeletable, source, status, sharedWith } = connector;

  const classes = useStyles();

  const dispatch = useDispatch();

  const { t } = useTranslations();

  const { data: flags } = useFeatureFlags();

  const { data: user } = useUserProfile();

  const syncState = useConnectorsSyncStates().data?.[connectorId];

  const [showReallyDeleteDialog, setShowReallyDeleteDialog] = useState(false);
  const [confirmShareDialogOpen, setConfirmShareDialogOpen] = useState(false);

  const connectorInDeletion = syncState?.status === ConnectorSyncStatus.InDeletion;

  const { mutateAsync: updateConnector } = useUpdateConnector();

  const { mutate: startSync } = useStartSync();

  return (
    <ListItem style={{ opacity: connectorInDeletion ? '0.7' : '1' }}>
      <SourceLogo className={classes.sourceIcon} source={source} unknownSourceTooltip />
      <ListItemText className={classes.sourceText} primary={name} secondary={email} />
      {connector.sharedWith && flags?.withSharedConnectors.enabled ? (
        <SharedConnectorChip
          connectorOwner={connector.email}
          connectorOwnerId={connector.ownerId}
          userId={user?.id}
        />
      ) : null}
      {status === ConnectorCredentialsStatus.Down ? (
        <ConnectorStatusWarning connectorId={connectorId} email={email} source={source} />
      ) : null}
      <ManageSourceMenu
        connectorId={connectorId}
        connectorName={name}
        deleteDisabled={connectorInDeletion || notDeletable || connector.ownerId !== user?.id}
        isRefreshing={
          syncState?.status === ConnectorSyncStatus.InProgress ||
          syncState?.status === ConnectorSyncStatus.Starting
        }
        onDelete={() => setShowReallyDeleteDialog(true)}
        onRefresh={() => startSync({ connectorId, source })}
        onShare={() => setConfirmShareDialogOpen(true)}
        refreshDisabled={connectorInDeletion}
        shareDisabled={!!sharedWith}
        source={source}
        syncState={syncState}
      />
      <DeleteConnector
        connector={connector}
        onClose={() => setShowReallyDeleteDialog(false)}
        showReallyDeleteDialog={showReallyDeleteDialog}
      />
      <ConfirmActionDialog
        confirmButtonText={t('confirm')}
        dialogTitle={t('share_this_source')}
        onActionConfirmed={async () => {
          await updateConnector({ connectorId, isShared: true, source });
          dispatch(Actions.notifySourceShared());
        }}
        onClose={() => setConfirmShareDialogOpen(false)}
        open={confirmShareDialogOpen}
        textHelper={t('share_source_helper')}
      />
    </ListItem>
  );
};

interface ManageSourceElementProps {
  connector: Connector & { status?: ConnectorCredentialsStatus };
}
