import { Connector, SingleRootConnectors, sources } from '@outmind/types';
import React, { memo, useEffect, useState } from 'react';
import useRouter from 'use-react-router';

import {
  CustomDialog,
  SelectRoots,
  ShareConnectorCheckBox,
  SourceLogo,
  SyncDiscoveredRootsCheckBox,
} from '../..';
import { useFeatureFlags, useStartSync, useTranslations, useUpdateConnector } from '../../../hooks';
import { Button, CircularProgress, DialogContentText, TextField } from '../../../material';
import { Actions, useDispatch } from '../../../store';
import { useStyles } from '../styles';
import { CancelConfigureSource } from './CancelConfigureSource';

const ConfigureSourceDialogNP: React.FC<ConfigureSourceDialogProps> = ({
  connector,
  isManagingSource,
  setShouldShowDialog,
  shouldShowDialog,
}) => {
  const { id: connectorId, name, source } = connector;

  const classes = useStyles({ shouldDisplayRootsFullHeight: sources[source]?.hasRoots });

  const { t } = useTranslations();

  const dispatch = useDispatch();

  const { history } = useRouter();

  const { data: flags } = useFeatureFlags();

  const [dialogIsOpen, setDialogIsOpen] = useState(true);

  const [connectorName, setConnectorName] = useState(name ?? sources[source].name);

  const [startSyncButtonIsLoading, setStartSyncButtonIsLoading] = useState(false);
  const [roots, setRoots] = useState(false);
  const [rootsToSyncIds, setRootsToSyncIds] = useState<Record<string, boolean>>({});
  const [syncDiscoveredRoots, setSyncDiscoveredRoots] = useState(true);
  const [showCancelConfigureSourceDialog, setShowCancelConfigureSourceDialog] = useState(false);
  const [isShared, setIsShared] = useState(false);

  const sourceInSingleRootConnector = SingleRootConnectors.includes(source);

  const { mutateAsync: updateConnector } = useUpdateConnector();

  const { mutateAsync: startSync } = useStartSync();

  const onClick = async (): Promise<void> => {
    setStartSyncButtonIsLoading(true);
    await updateConnector(
      {
        connectorId,
        isShared,
        name: connectorName,
        rootsToSyncIds,
        source,
        syncDiscoveredRoots,
      },
      isManagingSource
        ? {
            onSuccess: () => {
              setDialogIsOpen(false);
            },
          }
        : undefined,
    );
    if (!isManagingSource) {
      await startSync(
        { connectorId, source },
        {
          onError: () => {
            setStartSyncButtonIsLoading(false);
            dispatch(Actions.notifyStartSyncFailed());
          },
          onSuccess: () => {
            setDialogIsOpen(false);
            dispatch(Actions.showPreconfiguredSourcesDialog(true));
            history.push('/');
          },
        },
      );
    }
  };

  const toggleIsShared = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIsShared(event.target.checked);
  };

  const onClose = (): void => {
    if (isManagingSource) {
      setDialogIsOpen(false);
    } else {
      setShowCancelConfigureSourceDialog(true);
    }
  };

  useEffect(() => {
    setShouldShowDialog?.(dialogIsOpen);
  }, [dialogIsOpen]);

  return (
    <CustomDialog
      classes={{
        paper: classes.singleDialog,
      }}
      classNames={{ content: classes.dialogContent }}
      onClose={onClose}
      open={shouldShowDialog ?? dialogIsOpen}
      scroll="body"
      title={isManagingSource ? t('source_settings') : t('configure_new_source')}
    >
      <DialogContentText>{t('source_name')}</DialogContentText>
      <div className={classes.connectorHeader}>
        <div className={classes.connectorLogoContainer}>
          <SourceLogo className={classes.connectorLogo} source={source} unknownSourceTooltip />
        </div>
        <TextField
          className={classes.connectorNameInput}
          onChange={(e) => setConnectorName(e.target.value)}
          placeholder={t('connector_name')}
          value={connectorName}
          variant="outlined"
        />
      </div>
      {sources[source]?.hasRoots ? (
        <>
          <DialogContentText className={classes.dialogSubtitle}>
            {isManagingSource ? t('synced_items') : t('choose_synced_items')}
          </DialogContentText>
          <div className={classes.selectRootsContainer}>
            <SelectRoots
              connectorId={connector.id}
              disabled={isManagingSource}
              setRoots={setRoots}
              setSelectedRootsIds={setRootsToSyncIds}
              source={connector.source}
            />
          </div>
        </>
      ) : null}
      {!isManagingSource ? (
        <div className={classes.checkBoxesContainer}>
          {!sourceInSingleRootConnector && (
            <SyncDiscoveredRootsCheckBox
              setSyncDiscoveredRoots={setSyncDiscoveredRoots}
              sourceName={source}
              syncDiscoveredRoots={syncDiscoveredRoots as boolean}
            />
          )}
          {flags?.withSharedConnectors.enabled ? (
            <ShareConnectorCheckBox isShared={isShared} toggleIsShared={toggleIsShared} />
          ) : null}
        </div>
      ) : null}
      <div className={classes.startSyncButtonContainer}>
        <Button
          className={classes.startSyncButton}
          color="primary"
          disabled={
            !connectorName || (roots && sources[source]?.hasRoots) || startSyncButtonIsLoading
          }
          onClick={onClick}
          size="large"
          variant="outlined"
        >
          {isManagingSource ? t('save') : t('start_synchronization')}
          {startSyncButtonIsLoading ? (
            <CircularProgress className={classes.startSyncButtonLoader} size={20} />
          ) : null}
        </Button>
      </div>
      {!isManagingSource ? (
        <CancelConfigureSource
          connector={connector}
          onClose={() => setShowCancelConfigureSourceDialog(false)}
          onDelete={() => {
            setDialogIsOpen(false);
            history.push('/');
          }}
          showReallyDeleteDialog={showCancelConfigureSourceDialog}
        />
      ) : null}
    </CustomDialog>
  );
};

export const ConfigureSourceDialog = memo(ConfigureSourceDialogNP);

interface ConfigureSourceDialogProps {
  connector: Pick<Connector, 'id' | 'source'> & { name?: string };
  isManagingSource?: boolean;
  setShouldShowDialog?: (bool: boolean) => void;
  shouldShowDialog?: boolean;
}
