import { sourcesProvideIndex } from '@outmind/helpers';
import {
  DocumentIndiceNames,
  ElasticFile,
  FileCategory,
  NonIndexedDocumentNames,
  SearchableDocuments,
  SearchByFieldOptions,
  SearchResult,
} from '@outmind/types';
import axios from 'axios';
import qs from 'qs';
import { useInfiniteQuery, UseInfiniteQueryResult, useQueryClient } from 'react-query';

import { useSelector } from '../../store';
import { useConnectors } from '../connectors';
import { ApiQuery, useApiRouteMaker } from '../useApi';

/**
 * Hook used for retrieving the search results of the provided `index`
 */
export const useSearch = (
  index: SearchableDocuments,
  options?: searchOptions,
): UseSearchResponse => {
  const { data: connectors = [] } = useConnectors();

  const searchParams = useSearchParams(index, options);

  const sources = connectors.map((connector) => connector.source);
  const indexIsAvailable = sourcesProvideIndex(sources, index);

  const queryClient = useQueryClient();

  const route = useApiRouteMaker(ApiQuery.SEARCH)();

  const searchQuery = useInfiniteQuery<SearchResponse>(
    [ApiQuery.SEARCH, searchParams],
    async ({ pageParam: page = 0 }) => {
      const response = await axios({
        data: searchParams,
        method: route.method,
        params: { page },
        paramsSerializer: (params) => qs.stringify(params),
        url: route.url,
        withCredentials: true,
      });

      response.data.results.forEach((result: SearchResult) => {
        const { document: unknownDoc, similar } = result;

        if (['account', 'contact', 'opportunity', 'person'].includes(unknownDoc.type)) return;

        const { id, labels } = unknownDoc as ElasticFile;

        if (labels) {
          queryClient.setQueryData<string[]>(
            [ApiQuery.GET_DOCUMENT_LABELS, id],
            labels.map((label) => label.id),
          );
        }

        if (similar) {
          similar.documents.forEach((document) => {
            const similarDocumentsLabels = document.labels;
            queryClient.setQueryData<string[]>(
              [ApiQuery.GET_DOCUMENT_LABELS, document.id],
              similarDocumentsLabels.map((label) => label.id),
            );
          });
        }
      });

      return {
        ...response.data,
        nextPage: response.data.hasMore ? page + 1 : undefined,
      };
    },
    {
      enabled: options?.enabled ?? indexIsAvailable,
      getNextPageParam: ({ nextPage }) => nextPage,
      refetchOnWindowFocus: false,
    },
  );

  return {
    ...searchQuery,
    results: searchQuery.data?.pages.map((page) => page.results).flat() ?? [],
    total: Math.max(...(searchQuery.data?.pages.map((page) => page.total) ?? [0])),
  };
};

/**
 * Hook used to retrieve the search results of all the available indexes simultaneously
 */
export const useAllSearch = (): Record<SearchableDocuments, UseSearchResponse> => {
  const allIndices = [
    ...Object.values(DocumentIndiceNames),
    ...Object.values(NonIndexedDocumentNames),
  ];

  return Object.fromEntries(allIndices.map((index) => [index, useSearch(index)])) as Record<
    SearchableDocuments,
    UseSearchResponse
  >;
};

/**
 * Hook used to retrieve the search parameters for the provided `index`
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSearchParams = (index: SearchableDocuments, options?: searchOptions) => {
  const {
    connectors: connectorFilters,
    date,
    tabFilters: { fields, categories },
    folder,
    label,
    person,
    relatedDocuments,
  } = useSelector((s) => s.filters);
  const { chosenSortBy, q, queryId, mode } = useSelector((s) => s.search);

  const { data: connectors = [] } = useConnectors();

  const getConnectorIds = (): string[] => {
    const activeConnectors = connectors.filter((connector) => connectorFilters[connector.id]);
    return activeConnectors.length === 0
      ? connectors.map((connector) => connector.id)
      : activeConnectors.map((connector) => connector.id);
  };

  const activeCategories: FileCategory[] = categories
    .filter((filter) => filter.isActive)
    .map((filter) => filter.category);

  const activeFields: SearchByFieldOptions[] = fields
    .filter((filter) => filter.isActive)
    .map((filter) => filter.field);

  const connectorIds = getConnectorIds();

  return {
    byPerson: person?.email,
    byRelatedDocuments: relatedDocuments
      ? relatedDocuments.map(({ document, indexName }) => ({ id: document.id, indexName }))
      : undefined,
    categories: activeCategories.length ? activeCategories : undefined,
    clusterId: options?.clusterId,
    connectorIds,
    fields: activeFields.length ? activeFields : undefined,
    from: date.from?.toISOString(),
    index,
    inFolderId: folder?.id,
    labelId: label?.id ?? options?.bookmarkId,
    q,
    queryId,
    sortBy: mode === 'GET_LAST_ELEMENTS' ? 'date-desc' : chosenSortBy,
    to: date.to?.toISOString(),
  };
};

/**
 * Describes the output of the `useInfiniteQuery` hook
 */
interface SearchResponse {
  nextPage?: number;
  results: SearchResult[];
  total: number;
}

/**
 * Describes the output of the `useSearch` hook
 */
type UseSearchResponse = Omit<SearchResponse, 'nextPage'> & UseInfiniteQueryResult<SearchResponse>;

interface searchOptions {
  bookmarkId?: string;
  clusterId?: string;
  enabled?: boolean;
}
